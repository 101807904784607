/** @jsx jsx */
import {jsx} from 'theme-ui'
import {Fragment} from 'react'
import * as Sentry from '@sentry/browser'

type Props = {}

const TestPage: React.FC<Props> = () => {
  return (
    <Fragment>
      <button
        onClick={() => {
          Sentry.captureMessage('TEST SENTRY MESSAGE')
        }}
      >
        Test message
      </button>
      <button
        onClick={() => {
          throw new Error('Test sentry Error')
        }}
      >
        Test Crash
      </button>
      <button
        onClick={() => {
          Sentry.captureException(new Error('Test Capture error'))
        }}
      >
        Test Crash
      </button>
    </Fragment>
  )
}

export default TestPage
